@import "../../styles/fonts";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.headline {
  @include space-bold;
  font-size: 60px;
  margin-bottom: 20px;
}
