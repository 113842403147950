@mixin space-regular {
  font-family: "Space Mono", monospace;
}

@mixin space-bold {
  font-family: "Space Mono", monospace;
  font-weight: bold;
}

@mixin inter {
  font-family: "Inter", sans-serif;
}

body {
  @include space-regular;
}
