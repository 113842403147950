@import "../../styles/fonts";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.contentContainer {
  display: flex;
}

.verticalDivider {
  margin: 0 15px;
  width: 2px;
}

.leftSide {
  text-align: right;
  font-size: 80px;
  cursor: pointer;
  @include space-bold;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & div {
    transition: transform 0.5s;
    &:hover {
      transform: translate(5px, 0);
    }
  }
}
